export const enUS_users = {
    'users.title': 'Users',
    'users.add': 'Add User',
    'users.add.success': 'User Added Successfully',
    'users.edit.success': 'User Edited Successfully',
    'users.add.contact': 'Add Contact',
    'users.edit': 'Edit User',
    'users.profile': 'My Profile',
    'users.add.apiTokens': 'API Tokens',
    'users.add.totalApiTokens': 'Total API Tokens',
    'users.add.createToken': 'Create API Token',
    'users.add.token': 'Token',
    'users.add.scope': 'Scope',
    'users.add.expires': 'Expires',
    'users.add.expiresDescription': 'If left empty the token will never expire.',
    'users.add.tokenDescription': 'The token is only shown once, so make sure to copy it now.',
    'users.add.apiTokensDescription': 'Represents the number of issued tokens. (Some might be expried)',
    'users.add.invalidateTokens': 'Invalidate All Issued API Tokens',
    'users.add.invalidateTokensConfirmation':
        'Are you sure you want to invalidate all issued API Tokens? Type "invalidate" to confirm',
    'users.add.invalidateTokensSuccess': 'All issued API Tokens have been invalidated',
    'users.add.createAPIToken': 'Create new API Token',
    'users.table.header.name': 'Name',
    'users.table.header.email': 'Email',
    'users.table.header.permissions': 'Permissions',
};
