import { enUS_account } from './account';
import { enUS_channels } from './channels';
import { en_US_component } from './component';
import { enUS_customers } from './customers';
import { enUS_dashboard } from './dashboard';
import { enUS_globalTips } from './global/tips';
import { enUS_instances } from './instances';
import { enUS_notice } from './notice';
import { enUS_partners } from './partners';
import { enUS_permissionRole } from './permission/role';
import { enUS_avatorDropMenu } from './user/avatorDropMenu';
import { enUS_tagsViewDropMenu } from './user/tagsViewDropMenu';
import { enUS_title } from './user/title';
import { enUS_users } from './users';
import { enUS_websiteChat } from './website-chat';

const en_US = {
    ...enUS_account,
    ...enUS_avatorDropMenu,
    ...enUS_tagsViewDropMenu,
    ...enUS_title,
    ...enUS_globalTips,
    ...enUS_permissionRole,
    ...enUS_dashboard,
    ...enUS_notice,
    ...en_US_component,
    ...enUS_instances,
    ...enUS_customers,
    ...enUS_channels,
    ...enUS_users,
    ...enUS_partners,
    ...enUS_websiteChat,
};

export default en_US;
