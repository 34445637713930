import type { FC } from 'react';

import './index.less';

import { Dropdown, Layout, message,theme as antTheme, Tooltip } from 'antd';
import { createElement } from 'react';
import { AiOutlineApi, AiOutlineLogout, AiOutlineMenuFold, AiOutlineMenuUnfold, AiOutlineSmile, AiOutlineUser } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import BlankProfilePicture from '@/assets/header/blankProfilePicture.png';
import { ReactComponent as EnUsSvg } from '@/assets/header/en_US.svg';
import { ReactComponent as LanguageSvg } from '@/assets/header/language.svg';
import { ReactComponent as MoonSvg } from '@/assets/header/moon.svg';
import { ReactComponent as SunSvg } from '@/assets/header/sun.svg';
import Logo from '@/assets/logo/texterBigLogoBlue.svg';
import { LocaleFormatter, useLocale } from '@/locales';
import { setGlobalState } from '@/stores/global.store';
import { setUI } from '@/stores/ui.store';

import { logoutAsync } from '../../../stores/user.action';
import HeaderNoticeComponent from './notice';

const { Header } = Layout;

interface HeaderProps {
    collapsed: boolean;
    toggle: () => void;
}

type Action = 'userInfo' | 'userSetting' | 'logout';

const HeaderComponent: FC<HeaderProps> = ({ collapsed, toggle }) => {
    const { user } = useSelector((state) => state.user);
    const { locale, device } = useSelector((state) => state.ui);
    const { theme } = useSelector((state) => state.global);
    const { myPartner } = useSelector((state) => state.partners);
    const navigate = useNavigate();
    const token = antTheme.useToken();
    const dispatch = useDispatch();
    const { formatMessage } = useLocale();

    const onActionClick = async (action: Action) => {
        switch (action) {
            case 'userInfo':
                return;
            case 'userSetting':
                return;
            case 'logout':
                const success = Boolean(await dispatch(logoutAsync()));

                if (success) {
                    navigate('/login');
                } else {
                    message.error(formatMessage({ id: 'global.tips.logoutFailed' }));
                }

                return;
        }
    };

    const toLogin = () => {
        navigate('/login');
    };

    const selectLocale = ({ key }: { key: any }) => {
        dispatch(
            setUI({
                locale: key,
            }),
        );
        localStorage.setItem('locale', key);
    };

    const onChangeTheme = () => {
        const newTheme = theme === 'dark' ? 'light' : 'dark';

        localStorage.setItem('theme', newTheme);
        dispatch(
            setGlobalState({
                theme: newTheme,
            }),
        );
    };

    return (
        <Header className="layout-page-header bg-2" style={{ backgroundColor: token.token.colorBgContainer }}>
            {device !== 'MOBILE' && (
                <div className="logo" style={{ width: collapsed ? 80 : 200 }}>
                    <img src={Logo} alt="" />
                </div>
            )}
            <div className="layout-page-header-main">
                <div onClick={toggle}>
                    <span id="sidebar-trigger">{collapsed ? <AiOutlineMenuUnfold /> : <AiOutlineMenuFold />}</span>
                </div>
                <div className="actions">
                    <Tooltip
                        title={formatMessage({
                            id: theme === 'dark' ? 'global.tips.theme.lightTooltip' : 'global.tips.theme.darkTooltip',
                        })}
                    >
                        <span>
                            {createElement(theme === 'dark' ? SunSvg : MoonSvg, {
                                onClick: onChangeTheme,
                            })}
                        </span>
                    </Tooltip>
                    <HeaderNoticeComponent />
                    <Dropdown
                        menu={{
                            onClick: (info) => selectLocale(info),
                            items: [
                                {
                                    key: 'en_US',
                                    icon: <EnUsSvg />,
                                    disabled: locale === 'en_US',
                                    label: 'English',
                                },
                            ],
                        }}
                    >
                        <span>
                            <LanguageSvg id="language-change" />
                        </span>
                    </Dropdown>

                    {!!user ? (
                        <Dropdown
                            menu={{
                                items: [
                                    {
                                        key: 'user',
                                        icon: <AiOutlineUser />,
                                        label: (
                                            <span onClick={() => navigate('/me')}>
                                                {user.name}
                                                {user.email ? ` (${user.email})` : ''}
                                            </span>
                                        ),
                                    },
                                    {
                                        key: 'apiTokens',
                                        icon: <AiOutlineApi />,
                                        label: (
                                            <div onClick={() => navigate('/me/api-tokens')}>
                                                <LocaleFormatter id="header.avatar.apiTokens" />
                                            </div>
                                        ),
                                    },
                                    ...(user.partnerId && myPartner
                                        ? [
                                              {
                                                  key: 'partner',
                                                  icon: <AiOutlineSmile />,
                                                  label: (
                                                      <div onClick={() => navigate(`/partners/${myPartner._id}`)}>
                                                          {myPartner.name}
                                                      </div>
                                                  ),
                                              },
                                          ]
                                        : []),
                                    {
                                        key: 'logout',
                                        icon: <AiOutlineLogout />,
                                        label: (
                                            <div onClick={() => onActionClick('logout')}>
                                                <LocaleFormatter id="header.avatar.logout" />
                                            </div>
                                        ),
                                    },
                                ],
                            }}
                        >
                            <span className="user-action">
                                <img src={BlankProfilePicture} className="user-avator" alt="avator" />
                            </span>
                        </Dropdown>
                    ) : (
                        <span style={{ cursor: 'pointer' }} onClick={toLogin}>
                            {formatMessage({ id: 'global.tips.login' })}
                        </span>
                    )}
                </div>
            </div>
        </Header>
    );
};

export default HeaderComponent;
