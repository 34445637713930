import MyInput from "@/components/basic/input";
import { useLocale } from "@/locales";

interface Props {
  search: string;
  setSearch: (search: string) => void;
}

const ListFilters: React.FC<Props> = ({ search, setSearch }) => {
  const { formatMessage } = useLocale();

  return (
    <div className="customers-filters">
      <div className="customers-filters-box">
        <label className="text-2">
          {formatMessage({ id: "customers.access.filters.search" })}
        </label>
        <MyInput
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          size="middle"
          allowClear
          placeholder="Search by name, or versions"
          className="access-filters-select-wrapper website-chat-search-field"
        />
      </div>
    </div>
  );
};

export default ListFilters;
