import type { Facebook } from '../../../common/routes/channels';
import type { ChannelsState } from './channels.store';
import type { Dispatch } from '@reduxjs/toolkit';

import {
    apiAddFacebookUserToken,
    apiAddFacebookWebhook,
    apiDeleteFacebookUserToken,
    apiDeleteFacebookWebhook,
    apiEditFacebookUserToken,
    apiGetFacebookUserTokens,
    apiGetFacebookWebhooks,
    apiGetSingleFacebookUserToken,
    apiRegisterWhatsappPhoneNumber,
    apiSubscribeFacebookWebhook,
    apiUnsubscribeFacebookWebhook,
} from '../api/channels.api';
import {
    deleteFacebookUserToken,
    editFacebookUserToken,
    setFacebookUserTokens,
    unsetFacebookUserTokens,
} from './channels.store';
import { createAsyncAction } from './utils';

type WithResetParam<T> = T & { reset?: boolean };
type WithIdParam<T> = T & { id: string };

export const loadFacebookUserTokensAsync = createAsyncAction<WithResetParam<Facebook.Tokens.ListQuery>>(
    (params: WithResetParam<Facebook.Tokens.ListQuery>) => {
        return async (dispatch: Dispatch, getState: () => { channels: ChannelsState }) => {
            const { skip, reset } = params;

            if (reset) {
                dispatch(unsetFacebookUserTokens());
            }

            const { channels } = getState();
            const query: ChannelsState['facebook']['tokens']['query'] = {
                limit: channels.facebook.tokens.query.limit,
                skip: !skip ? channels.facebook.tokens.query.skip : skip,
            };

            const { data } = await apiGetFacebookUserTokens(query);

            if (data) {
                dispatch(setFacebookUserTokens({ data, query }));

                return true;
            }

            return false;
        };
    },
);

export const addFacebookUserTokenAsync = createAsyncAction<Facebook.Tokens.AddBody>((payload) => {
    return async (dispatch) => {
        const { data } = await apiAddFacebookUserToken(payload);

        if (data) {
            dispatch(loadFacebookUserTokensAsync({}));

            return true;
        }

        return false;
    };
});

export const editFacebookUserTokenAsync = createAsyncAction<{ id: string; body: Facebook.Tokens.PatchBody }>(
    (payload) => {
        return async (dispatch) => {
            const { data } = await apiEditFacebookUserToken(payload.id, payload.body);

            if (data) {
                dispatch(editFacebookUserToken({ id: payload.id, notes: payload.body.notes }));

                return true;
            }

            return false;
        };
    },
);

export const deleteFacebookUserTokenAsync = createAsyncAction<string>((payload) => {
    return async (dispatch) => {
        const { data } = await apiDeleteFacebookUserToken(payload);

        if (data) {
            dispatch(deleteFacebookUserToken(payload));
        }
    };
});

export const getSingleFacebookUserTokenAsync = createAsyncAction<string>((payload) => {
    return async (dispatch) => {
        const { data } = await apiGetSingleFacebookUserToken(payload);

        if (data) {
            /* */
        }
    };
});

export const loadFacebookWebhooksAsync = createAsyncAction<Facebook.WebhooksRouter.ListQuery>(
    (params: Facebook.WebhooksRouter.ListQuery) => {
        return async () => {
            const { data } = await apiGetFacebookWebhooks(params);

            if (data) {
                return data;
            }

            return false;
        };
    },
);

export const addFacebookWebhookAsync = createAsyncAction<Facebook.WebhooksRouter.AddBody>((payload) => {
    return async () => {
        const { data } = await apiAddFacebookWebhook(payload);

        if (data) {
            return true;
        }

        return false;
    };
});

export const subscribeFacebookWebhookAsync = createAsyncAction<WithIdParam<Facebook.WebhooksRouter.SubscribeBody>>((payload) => {
    return async () => {
        const { id, ...subscribeBody } = payload;
        const { data } = await apiSubscribeFacebookWebhook(id, subscribeBody);

        if (data) {
            return true;
        }

        return false;
    };
});

export const unsubscribeFacebookWebhookAsync = createAsyncAction<WithIdParam<Facebook.WebhooksRouter.UnsubscribeBody>>((payload) => {
    return async () => {
        const { id, ...unsubscribeBody } = payload;
        const { data } = await apiUnsubscribeFacebookWebhook(id, unsubscribeBody);

        if (data) {
            return true;
        }

        return false;
    };
});

export const deleteFacebookWebhookAsync = createAsyncAction<string>((payload) => {
    return async () => {
        const { data } = await apiDeleteFacebookWebhook(payload);

        if (data) {
            return data;
        }

        return false;
    };
});

export const registerWhatsappPhoneNumberAsync = createAsyncAction<Facebook.WhatsApp.PhoneNumber.RegisterBody>((payload) => {
    return async () => {
        const { data } = await apiRegisterWhatsappPhoneNumber(payload);

        if (data) {
            return data.success;
        }

        return false;
    };
});