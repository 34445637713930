import type { Users } from '../../../common/routes/users';

import { request } from './request';

export const apiGetUsers = () => {
    return request<Users.ListResponse>('get', '/users');
};

export const apiAddUser = (params: Users.PostBody) => {
    return request<Users.PostResponse>('post', '/users', params);
};

export const apiEditUser = (id: string, params: Users.PatchBody) => {
    return request<Users.PatchResponse>('patch', `/users/${id}`, params);
};

export const apiDeleteUser = (id: string) => {
    return request<Users.PatchResponse>('delete', `/users/${id}`);
};

export const apiGetSingleUser = (id: string) => {
    return request<Users.GetResponse>('get', `/users/${id}`);
};

export const apiResetKeypair = (id: string) => {
    return request('post', `/users/api-authentication/${id}/reset-keypair`);
};

export const apiCreateToken = (params: Users.ApiAuthentication.CreateTokenBody) => {
    return request<Users.ApiAuthentication.CreateTokenResponse>(
        'post',
        '/users/api-authentication/create-token',
        params,
    );
};
