import type { Permission } from '../../../../../common/permissions';

import { List, Modal } from 'antd';

import permissionsLabels from '../../../../../common/permissions';

interface Props {
    id: string;
    permissions: Permission[];
    open: boolean;
    onCancel: () => void;
}

const ShowPermissionsModal: React.FC<Props> = ({ open, onCancel, permissions, id }) => (
    <Modal title="Permissions" open={open} onCancel={onCancel} footer={false}>
        <List
            dataSource={permissions}
            size="small"
            bordered
            renderItem={(item) => <List.Item key={id + item}>{permissionsLabels[item].title}</List.Item>}
        />
    </Modal>
);

export default ShowPermissionsModal;
