import type { ServerEvents } from "../emitter";

import store from "@/stores";
import { updateWebsiteChatDeployment } from "@/stores/channels.store";

import { Events } from "../../../../common/events";

export function attach(events: ServerEvents) {
  events.addListener(Events.Name.websiteChatStateUpdated, onUpdated);
}

export function detach(events: ServerEvents) {
  events.removeListener(Events.Name.websiteChatStateUpdated, onUpdated);
}

function onUpdated(data: Events.EventNameDataMap["websiteChatStateUpdated"]) {
  store.dispatch(updateWebsiteChatDeployment(data));
}
