export const enUS_facebook = {
    'facebook.tokens.title': `User Tokens`,
    'facebook.tokens.addToken': `Add Token`,
    'facebook.webhooks.title': `Webhooks router`,
    'facebook.webhooks.add': `Add Webhook Router App`,
    'facebook.webhooks.add.success': `Webhook Router App added successfully`,
    'facebook.webhooks.add.subscribeExtra': 'Enable to subscribe to the specified object and fields.',
    'facebook.webhooks.add.idExtra':
        'Custom name of the app. Can include alphanumberic characters, hyphens, and underscores, from 2 to 250 characters.',
    'facebook.webhooks.add.appIdExtra': 'Connected Facebook application ID from Facebook.',
    'facebook.webhooks.subscribe.success': `Webhook subscribed successfully`,
    'facebook.webhooks.unsubscribe.success': `Webhook unsubscribed successfully`,
    'facebook.tableHeader.name': `Name`,
    'facebook.tableHeader.scopes': `Scopes`,
    'facebook.tableHeader.expiresAt': `Expires At`,
    'facebook.tableHeader.notes': `Notes`,
    'facebook.tableHeader.id': `ID`,
    'facebook.tableHeader.appId': `Facebook App ID`,
    'facebook.tableHeader.verifyToken': `Verify Token`,
    'facebook.tableHeader.latestRequestTime': `Latest Request Time`,
    'facebook.tableHeader.requestCount24h': `Request Count 24h`,
    'facebook.addTokenModal.tokenValue': `Value`,
    'facebook.addTokenModal.tokenValue.placeholder': `Enter token value`,
    'facebook.addTokenModal.tokenValue.required': `Token value is required`,
    'facebook.addTokenModal.tokenNotes': `Notes (optional)`,
    'facebook.addTokenModal.title': `Add Token`,
    'facebook.addTokenModal.titleEdit': `Edit Token`,
    'facebook.deleteTokenModal.title': `Delete Token`,
    'facebook.deleteTokenModal.description': `Are you sure you want to delete this token?`,
    'facebook.deleteWebhookAppModal.title': `Delete app`,
    'facebook.deleteWebhookAppModal.description': `Are you sure you want to delete this app?`,
    'facebook.subscribeWebhookModal.title': `Subscribe Webhook`,
    'facebook.subscribeWebhookModal.description': `Please provide the following details to subscribe to the webhook.`,
    'facebook.unsubscribeWebhookModal.title': `Unsubscribe Webhook`,
    'facebook.unsubscribeWebhookModal.description': `Please provide the following details to unsubscribe from the webhook.`,
    'facebook.unsubscribeWebhookModal.subscriptionTypeExtra':
        'If no type is selected, all subscriptions will be removed.',
    'facebook.whatsapp.title': `WhatsApp`,
    'facebook.whatsapp.registerPhoneNumber.title': `Register Phone Number`,
    'facebook.whatsapp.registerPhoneNumber.register.success': `Phone number registered successfully`,
};

