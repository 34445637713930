import type { AxiosRequestConfig, Method } from 'axios';

import axios from 'axios';

import { BACKEND_URL } from '@/environment';
import store from '@/stores';
import { setGlobalState } from '@/stores/global.store';

import { responseErrorHandler } from './helpers';

const axiosInstance = axios.create({
    baseURL: BACKEND_URL,
    // All backend API endpoints authenticated by cookie
    withCredentials: true,
    // timeout: 6000,
});

axiosInstance.interceptors.request.use(
    (config) => {
        /** Don't dispatch loading for background tasks */
        if (config.params?.backgroundTask) {
            delete config.params.backgroundTask;

            return config;
        }

        store.dispatch(
            setGlobalState({
                loading: true,
            }),
        );

        return config;
    },
    (error) => {
        store.dispatch(
            setGlobalState({
                loading: false,
            }),
        );
        Promise.reject(error);
    },
);

axiosInstance.interceptors.response.use(
    (response) => {
        store.dispatch(
            setGlobalState({
                loading: false,
            }),
        );

        return response;
    },
    (error) => {
        store.dispatch(
            setGlobalState({
                loading: false,
            }),
        );

        const errorMessage = responseErrorHandler(error);

        return {
            status: false,
            message: errorMessage,
            result: null,
        };
    },
);

export type Response<T = any> = {
    status: boolean;
    message: string;
    result: T;
};

export type RequestResponse<T = any> = Promise<Response<T>>;

/**
 *
 * @param method - request methods
 * @param url - request url
 * @param data - request data or params
 */
export const request = <ResponseData = any>(
    method: Lowercase<Method>,
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
) => {
    switch (method) {
        case 'get':
            return axiosInstance.get<ResponseData>(url, {
                params: data,
                ...config,
            });
        case 'post':
            return axiosInstance.post<ResponseData>(url, data, config);
        case 'patch':
            return axiosInstance.patch<ResponseData>(url, data, config);
        case 'delete':
            return axiosInstance.delete<ResponseData>(url, {
                params: data,
                ...config,
            });
        default:
            return axiosInstance.get<ResponseData>(url, {
                params: data,
                ...config,
            });
    }
};
