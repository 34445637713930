import type { Permission } from '../../../../../common/permissions';
import type { Users } from '../../../../../common/routes/users';
import type { FC } from 'react';

import '../index.less';

import { Alert, message, Space, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { AiOutlineCopy } from 'react-icons/ai';
import { useDispatch } from 'react-redux';

import MyButton from '@/components/basic/button';
import MyDatePicker from '@/components/basic/date-picker';
import MyInput from '@/components/basic/input';
import MySelect from '@/components/basic/select';
import MyForm from '@/components/core/form';
import MyModal from '@/components/core/modal';
import { LocaleFormatter, useLocale } from '@/locales';
import { createTokenAsync } from '@/stores/users.action';

interface Props {
    visible: boolean;
    onCancel: () => void;
    availablePermissionsList: { label: string; value: Permission }[];
}

const CreateTokenModal: FC<Props> = ({ visible, onCancel, availablePermissionsList }) => {
    const [loading, setLoading] = useState(false);
    const { formatMessage } = useLocale();
    const dispatch = useDispatch();
    const [token, setToken] = useState<string | null>(null);

    const initialValues: Users.ApiAuthentication.CreateTokenBody = {
        scope: undefined,
        expires: undefined,
    };

    const onClose = async (formData?: Users.ApiAuthentication.CreateTokenBody) => {
        if (formData) {
            if (token) {
                setToken(null);

                return;
            }

            try {
                setLoading(true);

                if (formData.expires) {
                    formData.expires = dayjs(formData.expires).unix() * 1000;
                }

                const freshToken = await dispatch(createTokenAsync(formData));

                setToken(freshToken);
            } finally {
                setLoading(false);
            }
        } else {
            onCancel();
        }
    };

    const onCopyToClipboard = () => {
        if (token) {
            navigator.clipboard.writeText(token);
            message.success(formatMessage({ id: 'global.tips.copyToClipboardSuccess' }));
        }
    };

    return (
        <MyModal
            form={initialValues}
            formProps={{ layout: 'vertical', preserve: false, initialValues }}
            title={<LocaleFormatter id="users.add.createToken" />}
            open={visible}
            onClose={onClose}
            okText={
                !token ? <LocaleFormatter id="global.tips.create" /> : <LocaleFormatter id="global.tips.createAgain" />
            }
            cancelText={!token ? <LocaleFormatter id="global.cancel" /> : <LocaleFormatter id="global.close" />}
            confirmLoading={loading}
            styles={{
                body: {
                    marginTop: 24,
                },
            }}
            width={600}
            destroyOnClose
        >
            {token ? (
                <Alert
                    message={<LocaleFormatter id="global.success" />}
                    description={
                        <div className="add-token-container">
                            <div>
                                <LocaleFormatter id="users.add.tokenDescription" />
                            </div>
                            <Space.Compact>
                                <MyInput value={token} readOnly />
                                <Tooltip title={<LocaleFormatter id="global.tips.copyToClipboard" />} placement="top">
                                    <MyButton type="default" icon={<AiOutlineCopy />} onClick={onCopyToClipboard} />
                                </Tooltip>
                            </Space.Compact>
                        </div>
                    }
                    style={{ margin: '24px 0' }}
                    type="success"
                    showIcon
                />
            ) : (
                <>
                    <MyForm.Item
                        label={formatMessage({
                            id: 'users.add.scope',
                        })}
                        name="scope"
                    >
                        <MySelect mode="multiple" allowClear showSearch options={availablePermissionsList} />
                    </MyForm.Item>
                    <MyForm.Item
                        label={<LocaleFormatter id="users.add.expires" />}
                        name="expires"
                        extra={<LocaleFormatter id="users.add.expiresDescription" />}
                    >
                        <MyDatePicker
                            showTime={{
                                minuteStep: 15,
                                showSecond: false,
                            }}
                            format="MMM D, YYYY h:mm A"
                        />
                    </MyForm.Item>
                </>
            )}
        </MyModal>
    );
};

export default CreateTokenModal;
