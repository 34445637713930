
export const permissionsInfo: Readonly<{[permission in Permission]: PermissionInfo}> = Object.seal({
    listUsers: {
        title: 'List users',
    },
    manageUsers: {
        title: 'Manage users',
    },
    manageInstances: {
        title: 'Manage instances',
    },
    setupCustomer: {
        title: 'Setup customer',
    },
    listCustomers: {
        title: 'List customers',
    },
    manageFacebookUserTokens: {
        title: 'Manage Facebook user tokens',
    },
    listFacebookUserTokens: {
        title: 'List Facebook user tokens',
    },
    customersConfigsSafeAccess: {
        title: 'View customers non-secret configurations',
    },
    customersConfigsFullAccess: {
        title: 'View customers full configurations',
    },
    customersConfigsUpdate: {
        title: 'Update customers configurations',
    },
    customersAccessGrantApprove: {
        title: 'Approve or reject customer access grant requests',
    },
    customersChatsList: {
        title: 'Access customers chats list',
    },
    customersChannelsList: {
        title: 'Access customers channels list',
    },
    customersRolesList: {
        title: 'Access customers roles list',
    },
    partnersList: {
        title: 'List partners',
    },
    partnersManage: {
        title: 'Manage partners',
    },
    customersMigrateVersions: {
        title: 'Migrate customers versions',
    },
    customersMigrateInstances: {
        title: 'Migrate customers between instances',
    },
    facebookWebhooksRouterList: {
        title: 'List Facebook webhooks router apps',
    },
    facebookWebhooksRouterAdd: {
        title: 'Add Facebook webhooks router apps',
    },
    facebookWebhooksRouterModify: {
        title: 'Modify Facebook webhooks router apps',
    },  
    whatsappUtils: {
        title: 'WhatsApp utils',
    },
} as const);

export enum Permission {
    listUsers = 'listUsers',
    manageUsers = 'manageUsers',
    manageInstances = 'manageInstances',
    setupCustomer = 'setupCustomer',
    listCustomers = 'listCustomers',
    manageFacebookUserTokens = 'manageFacebookUserTokens',
    listFacebookUserTokens = 'listFacebookUserTokens',
    /**
     * Able to access customers configs but sanitized from
     * all properties that marked as secret
     */
    customersConfigsSafeAccess = 'customersConfigsSafeAccess',
    /**
     * Able to access customers configs with all properties
     */
    customersConfigsFullAccess = 'customersConfigsFullAccess',
    customersConfigsUpdate = 'customersConfigsUpdate',
    /**
     * Able to approve or reject access grant requests
     */
    customersAccessGrantApprove = 'customersAccessGrantApprove',
    customersChatsList = 'customersChatsList',
    customersChannelsList = 'customersChannelsList',
    customersRolesList = 'customersRolesList',
    partnersList = 'partnersList',
    partnersManage = 'partnersManage',
    customersMigrateVersions = 'customersMigrateVersions',
    customersMigrateInstances = 'customersMigrateInstances',
    facebookWebhooksRouterList = 'facebookWebhooksRouterList',
    facebookWebhooksRouterAdd = 'facebookWebhooksRouterAdd',
    facebookWebhooksRouterModify = 'facebookWebhooksRouterModify',
    whatsappUtils = 'whatsappUtils',
}

export interface PermissionInfo {
    title: string;
    description?: string;
}

export default permissionsInfo;
