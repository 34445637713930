import { Customer } from '../customer';
import { Partners } from '../partners';
import { WebsiteChat as WebsiteChatTypes } from '../website_chat';
import { OffsetPaginatedQuery, OffsetPaginatedQueryResult } from './_includes';

export namespace Customers {

    export type ListQuery<IsServer extends boolean = false> = {
        instanceId?: string;
        /**
         * Search customer ID
         */
        search?: string;
        partnerId?: string;
        /** Skip previous N results. Integer value. Default: 0 */
        skip?: IsServer extends true ? string : number;
        /** 
         * Limit results to N. Integer value. Default: 500
         */
        limit?: IsServer extends true ? string : number;
        sortField?: '_id' | 'instance';
        sortDirection?: 'asc' | 'desc';
    }

    export type ListResponse = {
        customers: (Customer.Info & {partner?: Partners.Partner})[],
        /**
         * Total number of customers
         */
        total: number,
    }

    export namespace Setup {

        export type SchemaResponse = Record<string, any>;

        export type PostBody = Record<string, any> & {
            /**
             * New customer unique ID
             */
            id: string;
            /**
             * Setup customer for specified partner
             */
            partnerId?: string;
            // Unfortuanalety, current implemenation of form generation on the frontend side
            // doesn't support "patternProperties", so using here array of objects having "key" property instead
            attributes?: Array<{
                key: string;
                value: string | string[];
            }>;
        }
    }

    export namespace WebsiteChat {

        type DeployBaseBody = {
            /**
             * Google Cloud Project name which act as a parent for the deployed service
             */
            gcpName: string;
        }

        export type DeployBackendBody = DeployBaseBody & {
            /**
             * Container image tag to deploy
             */
            imageTag: string;

            /**
             * Google Cloud Project data center location like 'europe-central2'
             */

            gcpServiceLocation: string;

            /**
             * Google Firebase data center location like 'europe-central2' (for list of available locations please check https://cloud.google.com/firestore/docs/locations)
             */

            gcpFirestoreLocation: string;

            /**
             * Interval in milliseconds between retries of sending data to webhook from backend (environment variable: WEBHOOK_REPEAT_INTERVAL)
             */
            backendWebhookRepeatInterval?: number | null;

            /**
             * Number of retries of sending data to webhook from backend (environment variable: WEBHOOK_REPEAT_COUNT)
             */
            backendWebhookRepeatCount?: number | null;
            /**
             * Customer ID to deploy service for
             */
            customer?: {
                /**
                 * Customer ID
                 */
                id: string;
                /**
                 * Instance ID
                 */
                instance: string;
                /**
                 * Account ID for the channel on the customer
                 */
                channelAccountId?: string;
            } | null;
        }

        export type DeployFrontendBody = DeployBaseBody & {
            /**
             * Version of the frontend code to deploy
             */
            version: string;
        }

        export type ListDeploymentsQuery<IsServer extends boolean = false> = OffsetPaginatedQuery<IsServer> & {
            search?: string;
            frontendVersion?: string;
            backendImageTag?: string;
            sortField?: ListDeploymentsSortField;
            sortDirection?: 'asc' | 'desc';
        };

        export type ListDeploymentsResponse = OffsetPaginatedQueryResult<WebsiteChatTypes.State>;

        export type GetDeploymentResponse = WebsiteChatTypes.State;

        export enum ListDeploymentsSortField {
            gcpName = 'gcpName',
            frontendVersion = 'frontend.version',
            backendImageTag = 'backend.imageTag',
        }
    }

    export namespace Config {

        export type GetResponse = {
            config: Record<string, any>;
            secretProperties: string[];
            /**
             * If true, config contains only non-secret data,
             * and al properties listed in secretProperties are removed
             */
            sanitized: boolean;
        }

        /**
         * A new config data
         */
        export type PatchBody = Record<string, any>;
    }

    export namespace Migrations {

        /**
         * @todo Better type
         */
        export type GetResponse = Record<string, any>;

        export type PostBody = {
            /**
             * Run reverse migration to specified version
             */
            reverse?: number,
        }

    }

    export namespace Move {

        export type GetQuery = MoveRequest;

        export type GetResponse = {
            /**
             * Type of migrations (if any) that needs to be applied to move customer to another instance
             */
            migrationsType: MigrationsType;
            /**
             * List of forward or reverse migrations that needs to be applied 
             * during moving customer to another instance
             */
            migrations: Array<{
                version: number;
                reversible: boolean;
                collections?: string[];
            }>;
            disallowed?: undefined;
            currentInstanceApi: number;
            targetInstanceApi: number;
        } | {
            /**
             * If moving disallowed, contains a reason
             */
            disallowed?: string;
            migrationsType?: undefined;
            migrations?: undefined;
        }

        export type PostBody = MoveRequest;

        export type MoveRequest = {
            toInstanceId: string;
        }

        export enum MigrationsType {
            forward = 'forward',
            reverse = 'reverse',
            none = 'none',
        }
    }

    export namespace Metadata {
            
        export type GetResponse = Customer.Metadata;

    }
}
