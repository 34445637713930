import type { IUser } from '../../../../common/users/interfaces';
import type { ColumnsType } from 'antd/es/table';
import type { FC } from 'react';

import './index.less';

import { Table, Tag } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MyButton from '@/components/basic/button';
import PermissionGate from '@/components/basic/permission-gate';
import { LocaleFormatter, useLocale } from '@/locales';
import { history } from '@/routes/history';
import { hasPermissions } from '@/stores/user.action';
import { loadUsersAsync } from '@/stores/users.action';

import permissionsLabels, { Permission } from '../../../../common/permissions';
import ShowPermissionsModal from './modals/ShowPermissionsModal';

const UsersPage: FC = () => {
    const dispatch = useDispatch();
    const { users } = useSelector((state) => state.users);
    const { user: myUser } = useSelector((state) => state.user);
    const { formatMessage } = useLocale();
    const [selectedUser, setSelectedUser] = useState<IUser | null>(null);

    const isAdmin = dispatch(hasPermissions([Permission.manageUsers]));

    useEffect(() => {
        dispatch(loadUsersAsync());
    }, []);

    const columns: ColumnsType<IUser> = [
        {
            title: formatMessage({ id: 'users.table.header.name' }),
            dataIndex: 'name',
        },
        {
            title: formatMessage({ id: 'users.table.header.email' }),
            dataIndex: 'email',
        },
        {
            title: formatMessage({ id: 'users.table.header.permissions' }),
            dataIndex: 'permissions',
            render: (permissions: Permission[], user) =>
                permissions.length === 0 ? null : (
                    <div>
                        <span>
                            <Tag key={permissions[0]} color="#546BD6">
                                {permissionsLabels[permissions[0]].title}
                            </Tag>
                            <Tag
                                color="#546BD6"
                                className="view-more-permissions"
                                onClick={() => {
                                    setSelectedUser(user);
                                }}
                            >
                                + {permissions.length - 1} more
                            </Tag>
                        </span>
                    </div>
                ),
        },
        {
            title: formatMessage({ id: 'customers.table.header.actions' }),
            render: (_, user) => {
                return (
                    <div className="table-actions">
                        {isAdmin && (
                            <MyButton
                                type="primary"
                                size="small"
                                onClick={() => {
                                    const isMe = user._id === myUser?._id;
                                    const url = isMe ? '/me/api-tokens' : `/users/${user._id}/edit/api-tokens`;

                                    history.push(url, { user });
                                }}
                            >
                                <LocaleFormatter id="users.add.apiTokens" />
                            </MyButton>
                        )}
                        <MyButton
                            type="primary"
                            size="small"
                            onClick={() => {
                                history.push(`/users/${user._id}/edit`, { user });
                            }}
                        >
                            <LocaleFormatter id="global.edit" />
                        </MyButton>
                    </div>
                );
            },
        },
    ];

    return (
        <div className="container">
            <div className="title">
                <h1>
                    <LocaleFormatter id="users.title" />
                </h1>
                <div className="title-actions">
                    <PermissionGate permissions={[Permission.manageUsers]}>
                        <MyButton type="primary" onClick={() => history.push('/users/add')}>
                            <LocaleFormatter id="users.add" />
                        </MyButton>
                    </PermissionGate>
                </div>
            </div>
            <div className="content">
                <Table columns={columns} dataSource={Object.values(users)} rowKey="_id" />
            </div>

            {selectedUser && (
                <ShowPermissionsModal
                    open={!!selectedUser}
                    id={selectedUser._id}
                    permissions={selectedUser.permissions}
                    onCancel={() => {
                        setSelectedUser(null);
                    }}
                />
            )}
        </div>
    );
};

export default UsersPage;
