import type { Dispatch } from "@reduxjs/toolkit";

import { Customers } from "../../../common/routes/customers";

import WebsiteChat = Customers.WebsiteChat;

import {
  apiDeployBackend,
  apiDeployFrontend,
  apiGetDeployment,
  apiGetDeployments,
} from "../api/website-chat.api";
import { setWebsiteChatDeployments } from "./channels.store";
import { createAsyncAction } from "./utils";

export const loadDeploymentsAsync = (
  query?: WebsiteChat.ListDeploymentsQuery
) => {
  return async (dispatch: Dispatch) => {
    const { data } = await apiGetDeployments(query);

    if (data) {
      dispatch(setWebsiteChatDeployments(data));
    }
  };
};

export const loadDeploymentInfoAsync = (gcpName: string) => {
  return async () => {
    const { data } = await apiGetDeployment(gcpName);

    return data;
  };
};

export const deployFrontendAsync =
  createAsyncAction<WebsiteChat.DeployFrontendBody>((payload) => {
    return async () => {
      const { data } = await apiDeployFrontend(payload);

      if (data) {
        return true;
      }

      return false;
    };
  });

export const deployBackendAsync =
  createAsyncAction<WebsiteChat.DeployBackendBody>((payload) => {
    return async () => {
      const { data } = await apiDeployBackend(payload);

      if (data) {
        return true;
      }

      return false;
    };
  });

export const bulkDeployFrontendAsync = createAsyncAction<
  WebsiteChat.DeployFrontendBody[]
>((payload) => {
  return async () => {
    for (const deployment of payload) {
      const { data } = await apiDeployFrontend(deployment);

      if (!data) {
        return false;
      }
    }

    return true;
  };
});

export const bulkDeployBackendAsync = createAsyncAction<
  WebsiteChat.DeployBackendBody[]
>((payload) => {
  return async () => {
    for (const deployment of payload) {
      const { data } = await apiDeployBackend(deployment);

      if (!data) {
        return false;
      }
    }

    return true;
  };
});
