import type { FunctionComponent } from "react";

import "./index.less";

import { Card, Col, message, Popconfirm, Row, Statistic, Tooltip } from "antd";
import { useForm } from "antd/es/form/Form";
import { useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import MyButton from "@/components/basic/button";
import MyInput from "@/components/basic/input";
import MyForm from "@/components/core/form";
import MyFormItem from "@/components/core/form-item";
import { LocaleFormatter, useLocale } from "@/locales";
import { history } from "@/routes/history";
import { resetKeypairAsync } from "@/stores/users.action";

import permissions from "../../../../common/permissions";
import { type IUser } from "../../../../common/users/interfaces";
import CreateTokenModal from "./modals/CreateTokenModal";

interface Props {
  myself?: boolean;
}

const ApiTokens: FunctionComponent<Props> = ({ myself }) => {
  const dispatch = useDispatch();
  const { user: myUser } = useSelector((state) => state.user);
  const { state } = useLocation();
  const { formatMessage } = useLocale();
  const [form] = useForm();

  const [invalidateValidator, setInvalidateValidator] = useState("");
  const [createTokenModalVisible, setCreateTokenModalVisible] = useState(false);

  const user = myself ? myUser : state?.user as IUser;
  const isMe = myself || myUser?._id === user?._id;

  // It should never happen but just in case
  if (!user) {
    history.push("/users");

    return null;
  }

  const onInvalidateTokens = async () => {
    if (user) {
      const success = await dispatch(resetKeypairAsync(user._id));

      setInvalidateValidator("");

      if (success) {
        message.success(
          formatMessage({ id: "users.add.invalidateTokensSuccess" })
        );
      }
    }
  };

  const availablePermissionsList =
    myUser?.permissions?.map((permission) => ({
      label: permissions[permission].title,
      value: permission,
    })) || [];

  return (
    <div className="container">
      <div className="title">
      <h1>
					<span className="subtitle">{user.name}</span>
          <LocaleFormatter id="title.users.manageApiTokens" />
				</h1>
        <h1>
        </h1>
        <div className="title-actions"></div>
      </div>
      <div className="users-content">
        <MyForm form={form} layout="vertical" scrollToFirstError>
          <MyFormItem className="api-tokens-container">
            <Row gutter={[16, 16]}>
              <Col flex={1}>
                <Card bordered={false}>
                  <Statistic
                    title={
                      <div className="api-tokens-statistic-title">
                        <span>
                          <LocaleFormatter id="users.add.totalApiTokens" />
                        </span>
                        <Tooltip
                          title={formatMessage({
                            id: "users.add.apiTokensDescription",
                          })}
                        >
                          <AiOutlineInfoCircle />
                        </Tooltip>
                      </div>
                    }
                    value={user?.apiKey?.tokensCount}
                  />
                </Card>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col flex={1} hidden={!isMe}>
                <Card bordered={false}>
                  <Statistic
                    title={formatMessage({ id: "users.add.createAPIToken" })}
                    formatter={() => (
                      <MyButton
                        type="primary"
                        onClick={() => setCreateTokenModalVisible(true)}
                      >
                        <LocaleFormatter id="global.tips.create" />
                      </MyButton>
                    )}
                  />
                </Card>
              </Col>
            </Row>
            <Row>
              <Col flex={1}>
                <Card bordered={false}>
                  <Statistic
                    title={formatMessage({
                      id: "users.add.invalidateTokens",
                    })}
                    formatter={() => (
                      <Popconfirm
                        title={formatMessage({
                          id: "users.add.invalidateTokens",
                        })}
                        description={
                          <div className="invalidate-token-confirm-wrapper">
                            <p>
                              <LocaleFormatter id="users.add.invalidateTokensConfirmation" />
                            </p>
                            <MyInput
                              size="small"
                              placeholder={'Type "invalidate" to confirm'}
                              onChange={(e) =>
                                setInvalidateValidator(e.target.value)
                              }
                            />
                          </div>
                        }
                        onConfirm={onInvalidateTokens}
                        onCancel={() => setInvalidateValidator("")}
                        okText={formatMessage({ id: "global.invalidate" })}
                        okButtonProps={{
                          danger: true,
                          disabled: invalidateValidator !== "invalidate",
                        }}
                        zIndex={1200}
                      >
                        <MyButton type="primary" danger>
                          <LocaleFormatter id="global.invalidate" />
                        </MyButton>
                      </Popconfirm>
                    )}
                  />
                </Card>
              </Col>
            </Row>
          </MyFormItem>
        </MyForm>

        <CreateTokenModal
          visible={createTokenModalVisible}
          onCancel={() => setCreateTokenModalVisible(false)}
          availablePermissionsList={availablePermissionsList}
        />
      </div>
    </div>
  );
};

export default ApiTokens;
