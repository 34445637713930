import { Customers } from "../../../common/routes/customers";
import WebsiteChat = Customers.WebsiteChat;

import { request } from "./request";

export const apiDeployBackend = (
  body: WebsiteChat.DeployBackendBody
) => {
  return request<WebsiteChat.DeployBackendBody>(
    "post",
    "/customers/websitechat/backend/deploy",
    body,
    {
      params: {
        backgroundTask: true,
      }
    }
  );
};

export const apiDeployFrontend = (
  body: WebsiteChat.DeployFrontendBody
) => {
  return request<WebsiteChat.DeployFrontendBody>(
    "post",
    "/customers/websitechat/frontend/deploy",
    body,
    {
      params: {
        backgroundTask: true,
      }
    }
  );
};

export const apiGetDeployments = (
  query?: WebsiteChat.ListDeploymentsQuery
) => {
  return request<WebsiteChat.ListDeploymentsResponse>(
    "get",
    "/customers/websitechat/deployments",
    query,
  );
};

export const apiGetDeployment = (gcpName: string) => {
  return request<WebsiteChat.GetDeploymentResponse>(
    "get",
    `/customers/websitechat/deployments/${gcpName}`
  );
};
