import type { MessageDescriptorId } from '@/locales';
import type { FunctionComponent } from 'react';

import './index.less';

import { Alert, Avatar, List, message, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import classNames from 'classnames';
import { omit } from 'lodash';
import { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';

import MyButton from '@/components/basic/button';
import MyInput from '@/components/basic/input';
import PermissionGate from '@/components/basic/permission-gate';
import MySelect from '@/components/basic/select';
import MySwitch from '@/components/basic/switch';
import MyForm from '@/components/core/form';
import MyFormItem from '@/components/core/form-item';
import { LocaleFormatter, useLocale } from '@/locales';
import { history } from '@/routes/history';
import { addUserAsync, editUserAsync } from '@/stores/users.action';
import getIconFromName from '@/utils/getIconFromName';

import permissions, { Permission } from '../../../../common/permissions';
import { type IUser, type UserContact, UserContactType } from '../../../../common/users/interfaces';

interface Props {
    title: MessageDescriptorId;
    user?: IUser;
    readOnly?: boolean;
}

const UserForm: FunctionComponent<Props> = ({ user, readOnly, title }) => {
    const dispatch = useDispatch();
    const { user: myUser, session } = useSelector((state) => state.user);
    const { formatMessage } = useLocale();
    const [form] = useForm();

    const [contacts, setContacts] = useState<UserContact[]>([]);
    const [contactType, setContactType] = useState<UserContactType | null>(null);
    const [contactValue, setContactValue] = useState<string>('');

    const isEdit = !!user;
    const isMe = myUser?._id === user?._id;

    const onAddContact = () => {
        if (contactType && contactValue) {
            setContacts([...contacts, { type: contactType, value: contactValue, secure: false }]);
            setContactType(null);
            setContactValue('');
        }
    };

    const onSubmit = async () => {
        const values = await form.validateFields();

        const newUser = {
            ...omit(values, ['IPsAllowed']),
            contacts,
            permissions: values.permissions || [],
            ...(values.IPsAllowed && { IPsAllowed: values.IPsAllowed.split(',').map((ip: string) => ip.trim()) }),
        };

        let success = false;
        let successMessage = '';

        if (isEdit) {
            success = await dispatch(editUserAsync(user._id, newUser));
            successMessage = formatMessage({ id: 'users.edit.success' });
        } else {
            success = await dispatch(addUserAsync(newUser));
            successMessage = formatMessage({ id: 'users.add.success' });
        }

        if (success) {
            form.resetFields();
            message.success(successMessage);
            history.push('/users');
        }
    };

    const availablePermissionsList =
        myUser?.permissions?.map((permission) => ({
            label: permissions[permission].title,
            value: permission,
        })) || [];

    useEffect(() => {
        if (user) {
            form.setFieldsValue(omit(user, ['contacts', 'IPsAllowed']));

            if (user.contacts) {
                setContacts(user.contacts);
            }

            if (user.IPsAllowed) {
                form.setFieldsValue({ IPsAllowed: user.IPsAllowed.join(', ') });
            }
        }
    }, [user]);

    return (
        <div className="container">
            <div className="title">
                <h1>
                    <LocaleFormatter id={title} />
                </h1>
                <div className="title-actions">
                    <PermissionGate permissions={[Permission.manageUsers]}>
                        {user && (
                            <MyButton
                                type="primary"
                                onClick={() => {
                                    const url = isMe ? '/me/api-tokens' : `/users/${user._id}/edit/api-tokens`;

                                    history.push(url, { user });
                                }}
                            >
                                <LocaleFormatter id="title.users.manageApiTokens" />
                            </MyButton>
                        )}
                    </PermissionGate>
                </div>
            </div>
            <div
                className={classNames('users-content', {
                    'read-only': readOnly,
                })}
            >
                {readOnly && <Alert message="Read-only mode" type="warning" showIcon />}
                <MyForm form={form} layout="vertical" scrollToFirstError disabled={readOnly}>
                    <MyFormItem name="name" label="Name" rules={[{ required: true }]}>
                        <MyInput />
                    </MyFormItem>
                    <MyFormItem name="email" label="Email">
                        <MyInput />
                    </MyFormItem>
                    <MyFormItem name="passwordCurrent" label="Current Password" hidden={!isMe}>
                        <MyInput.Password />
                    </MyFormItem>
                    <MyFormItem name="passwordNew" label="New Password" hidden={!isMe || session?.recovery}>
                        <MyInput.Password />
                    </MyFormItem>
                    <MyFormItem name="permissions" label="Permissions">
                        <MySelect mode="multiple" allowClear showSearch options={availablePermissionsList} />
                    </MyFormItem>
                    <MyFormItem label={`Contacts (${contacts.length})`}>
                        <div className="show-contacts-container">
                            {contacts?.length > 0 && (
                                <List
                                    dataSource={contacts}
                                    itemLayout="horizontal"
                                    size="small"
                                    bordered
                                    renderItem={({ type, value }) => (
                                        <List.Item
                                            actions={[
                                                <div
                                                    onClick={() =>
                                                        setContacts(
                                                            contacts.filter((contact) => contact.value !== value),
                                                        )
                                                    }
                                                >
                                                    <AiOutlineClose />
                                                </div>,
                                            ]}
                                        >
                                            <List.Item.Meta
                                                avatar={<Avatar src={getIconFromName(type)} />}
                                                title={value}
                                            />
                                        </List.Item>
                                    )}
                                />
                            )}
                        </div>
                        <div className="add-contacts-container">
                            <LocaleFormatter id="users.add.contact" />
                            <MySelect
                                placeholder="Select contact type"
                                value={contactType}
                                onChange={setContactType}
                                options={Object.values(UserContactType).map((type) => ({ label: type, value: type }))}
                            />
                            <Space.Compact style={{ width: '100%' }}>
                                <MyInput value={contactValue} onChange={(e) => setContactValue(e.target.value)} />
                                <MyButton
                                    type="primary"
                                    disabled={!contactType || !contactValue}
                                    onClick={onAddContact}
                                >
                                    Add
                                </MyButton>
                            </Space.Compact>
                        </div>
                    </MyFormItem>
                    <MyFormItem
                        name="IPsAllowed"
                        label="IP's Allowed (empty for unrestricted access)"
                        extra="Separate multiple URL's with commas"
                    >
                        <MyInput.TextArea />
                    </MyFormItem>
                    <MyFormItem name="partnerId" label="Partner ID" hidden={!!myUser?.partnerId}>
                        <MyInput />
                    </MyFormItem>
                    <MyFormItem name="disabled" label="Disable user" valuePropName="checked">
                        <MySwitch />
                    </MyFormItem>

                    {!readOnly && (
                        <MyButton type="primary" size="large" onClick={onSubmit}>
                            {isEdit ? <LocaleFormatter id="users.edit" /> : <LocaleFormatter id="users.add" />}
                        </MyButton>
                    )}
                </MyForm>
            </div>
        </div>
    );
};

export default UserForm;
