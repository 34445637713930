import type { Facebook } from '../../../common/routes/channels';
import type { Customers } from '../../../common/routes/customers';
import type { WebsiteChat } from '../../../common/website_chat';
import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';

const initialState: ChannelsState = {
    facebook: {
        tokens: {
            data: [],
            total: 0,
            query: {
                skip: 0,
                limit: 20,
            },
        },
    },
    websiteChat: {
        deployments: [],
        total: 0,
    }
};

const channelsSlice = createSlice({
    name: 'channels',
    initialState,
    reducers: {
        setFacebookUserTokens(
            state,
            action: PayloadAction<{
                data: Facebook.Tokens.ListResponse;
                query: ChannelsState['facebook']['tokens']['query'];
            }>,
        ) {
            state.facebook.tokens = {
                data: state.facebook.tokens.data.concat(action.payload.data.data),
                total: action.payload.data.total,
                query: action.payload.query,
            };
        },
        deleteFacebookUserToken(state, action: PayloadAction<string>) {
            state.facebook.tokens.data = state.facebook.tokens.data.filter((token) => token._id !== action.payload);
            state.facebook.tokens.total--;
        },
        editFacebookUserToken(state, action: PayloadAction<{ id: string; notes: string | null }>) {
            state.facebook.tokens.data = state.facebook.tokens.data.map((token) => {
                if (token._id === action.payload.id) {
                    return {
                        ...token,
                        notes: action.payload.notes,
                    };
                }

                return token;
            });
        },
        unsetFacebookUserTokens(state) {
            state.facebook.tokens = {
                ...initialState.facebook.tokens,
            };
        },
        setWebsiteChatDeployments(
            state,
            action: PayloadAction<{
                data: Customers.WebsiteChat.ListDeploymentsResponse['data'];
                total: Customers.WebsiteChat.ListDeploymentsResponse['total'];
            }>,
        ) {
            state.websiteChat = {
                deployments: action.payload.data,
                total: action.payload.total,
            };
        },
        updateWebsiteChatDeployment(
            state,
            action: PayloadAction<WebsiteChat.State>,
        ) {
            state.websiteChat.deployments = state.websiteChat.deployments.map((deployment) => {
                if (deployment.gcpName === action.payload.gcpName) {
                    return action.payload;
                }

                return deployment;
            });
        }
    },
});

export const {
    setFacebookUserTokens,
    deleteFacebookUserToken,
    editFacebookUserToken,
    unsetFacebookUserTokens,
    setWebsiteChatDeployments,
    updateWebsiteChatDeployment,
} = channelsSlice.actions;

export default channelsSlice.reducer;

export type ChannelsState = {
    facebook: {
        tokens: {
            data: Facebook.Tokens.ListResponse['data'];
            total: Facebook.Tokens.ListResponse['total'];
            query: {
                skip: number;
                limit: number;
            };
        };
    };
    websiteChat: {
        deployments: Customers.WebsiteChat.ListDeploymentsResponse["data"];
        total: Customers.WebsiteChat.ListDeploymentsResponse["total"];
    }
};
