import type { Facebook } from "../../../../../common/routes/channels";
import type { FC } from "react";

import "./index.less";

import { message } from "antd";
import { useDispatch } from "react-redux";

import { LocaleFormatter, useLocale } from "@/locales";
import { history } from "@/routes/history";
import { registerWhatsappPhoneNumberAsync } from "@/stores/channels.action";

import RegisterPhoneNumberForm from "./registerPhoneNumberForm";

const WhatsappPage: FC = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useLocale();

  const onSubmit = async (
    registerBody: Facebook.WhatsApp.PhoneNumber.RegisterBody
  ) => {
    const success = await dispatch(
      registerWhatsappPhoneNumberAsync(registerBody)
    );

    if (success) {
      message.success(
        formatMessage({
          id: "facebook.whatsapp.registerPhoneNumber.register.success",
        })
      );

      history.push("/channels/facebook/whatsapp");
    }
  };

  return (
    <div className="container">
      <div className="title">
        <h1>
          <span className="subtitle whatsapp-subtitle">Whatsapp</span>
          <LocaleFormatter id="facebook.whatsapp.registerPhoneNumber.title" />
        </h1>
        <div className="title-actions"></div>
      </div>
      <div className="content">
        <RegisterPhoneNumberForm onSubmit={onSubmit} />
      </div>
    </div>
  );
};

export default WhatsappPage;
