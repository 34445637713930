import type { WebsiteChat } from "../../../../common/website_chat";
import type { ColumnsType } from "antd/es/table";
import type { SorterResult } from "antd/es/table/interface";
import type { FC } from "react";

import "./index.less";

import { message, Popconfirm, Spin, Table } from "antd";
import { debounce, isArray } from "lodash";
import React, { useEffect, useState } from "react";
import { IoMdArrowDropright } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { apiGetDeployments } from "@/api/website-chat.api";
import MyButton from "@/components/basic/button";
import MyInput from "@/components/basic/input";
import PermissionGate from "@/components/basic/permission-gate";
import { LocaleFormatter, useLocale } from "@/locales";
import { setWebsiteChatDeployments } from "@/stores/channels.store";
import {
  bulkDeployBackendAsync,
  bulkDeployFrontendAsync,
  deployFrontendAsync,
} from "@/stores/website-chat.action";

import { Permission } from "../../../../common/permissions";
import { Customers } from "../../../../common/routes/customers";
import ListFilters from "./components/ListFilters";

const WebsiteChatPage: FC = () => {
  const dispatch = useDispatch();
  const { deployments } = useSelector((state) => state.channels.websiteChat);
  const { pipelines } = useSelector((state) => state.notification);
  const { formatMessage } = useLocale();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const navigate = useNavigate();
  const [selectedVersion, setSelectedVersion] = useState<string>("");

  /* Filter states */
  const [search, setSearch] = useState<string>("");
  const [sortField, setSortField] = useState<
    Customers.WebsiteChat.ListDeploymentsSortField | undefined
  >();
  const [sortDirection, setSortDeriction] = useState<"asc" | "desc">("desc");
  const [total, setTotal] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  const generateQuery = (skip?: number) => {
    const query: Customers.WebsiteChat.ListDeploymentsQuery = {
      limit,
      skip,
    };

    if (search) {
      query.search = search;
    }

    if (sortField) {
      query.sortField = sortField;
    }

    if (sortDirection) {
      query.sortDirection = sortDirection;
    }

    return query;
  };

  const loadDeployments = (
    query?: Customers.WebsiteChat.ListDeploymentsQuery
  ) => {
    apiGetDeployments(query)
      .then(({ data }) => {
        if (!query?.skip) {
          dispatch(setWebsiteChatDeployments(data));
          setPage(1);
        } else {
          dispatch(
            setWebsiteChatDeployments({
              data: deployments.concat(data.data),
              total: data.total,
            })
          );
        }

        setTotal(data.total);
      })
      .catch((error) => {
        console.error(error);
        message.error("Failed to load deployments");
      });
  };

  useEffect(() => {
    loadDeployments(generateQuery());
  }, [sortDirection, sortField]);

  useEffect(() => {
    if (search.length >= 3 || search === "") {
      debounce(() => {
        loadDeployments(generateQuery());
      }, 500)();
    }
    /** Debounced filters */
  }, [search]);

  const findDeploymentInProgress = (
    gcpName: string,
    component: "frontend" | "backend"
  ) => {
    return pipelines.find(
      (pipeline) =>
        pipeline.metadata?.service === "website_chat" &&
        pipeline.metadata?.gcpName === gcpName &&
        pipeline.metadata.component === component
    );
  };

  const deployBulkFrontend = () => {
    const selectedDeployments = selectedRowKeys.map((gcpName) => ({
      gcpName: gcpName as string,
      version: selectedVersion,
    }));

    dispatch(bulkDeployFrontendAsync(selectedDeployments));
  };

  const deployBulkBackend = () => {
    const selectedDeployments = deployments.filter((d) =>
      selectedRowKeys.includes(d.gcpName)
    );

    const selectedDeploymentsWithVersion = selectedDeployments.map((d) => ({
      gcpName: d.gcpName,
      imageTag: selectedVersion,
      /** gcpServiceLocation and gcpFirestoreLocation must be present here, as they were required
       *  to create the deployment in the first place
       */
      gcpServiceLocation: d.backend.gcpServiceLocation!,
      gcpFirestoreLocation: d.backend.gcpFirestoreLocation!,
    }));

    dispatch(bulkDeployBackendAsync(selectedDeploymentsWithVersion));
  };

  const onTableChange = (
    _: any,
    __: any,
    sorter: SorterResult<WebsiteChat.State> | SorterResult<WebsiteChat.State>[]
  ) => {
    if (isArray(sorter)) return;

    const field = isArray(
      sorter.field
    )
      ? sorter.field.join(".")
      : sorter.field;

    /** Check if the field is a valid sort field, re-casting React.Key */
    const sortField = field as Customers.WebsiteChat.ListDeploymentsSortField;

    if (
      Object.values(Customers.WebsiteChat.ListDeploymentsSortField).includes(
        sortField
      )
    ) {
      setSortField(sortField);
      setSortDeriction(sorter.order === "ascend" ? "asc" : "desc");
    }
  };

  const columns: ColumnsType<WebsiteChat.State> = [
    {
      title: formatMessage({ id: "websiteChat.table.header.gcpName" }),
      dataIndex: "gcpName",
      sorter: true,
    },
    {
      title: formatMessage({ id: "websiteChat.table.header.backendVersion" }),
      dataIndex: ["backend", "imageTag"],
      sorter: true,
      render: (imageTag: string, record) => {
        const deploymentInProgress = findDeploymentInProgress(
          record.gcpName,
          "backend"
        );

        return (
          <div className="version-container">
            {!!deploymentInProgress && <Spin size="small" />}
            <div className="new-version-container">
              <span>{imageTag}</span>
              {deploymentInProgress && (
                <>
                  <IoMdArrowDropright />
                  <span className="new-version">
                    {deploymentInProgress.metadata?.imageTag}
                  </span>
                </>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: formatMessage({ id: "websiteChat.table.header.backendUrl" }),
      dataIndex: ["backend", "url"],
    },
    {
      title: formatMessage({ id: "websiteChat.table.header.backendLocation" }),
      dataIndex: ["backend", "gcpServiceLocation"],
    },
    {
      title: formatMessage({
        id: "websiteChat.table.header.firestoreLocation",
      }),
      dataIndex: ["backend", "gcpFirestoreLocation"],
    },
    {
      title: formatMessage({ id: "websiteChat.table.header.frontendVersion" }),
      dataIndex: ["frontend", "version"],
      sorter: true,
      render: (version: string, record) => {
        const deploymentInProgress = findDeploymentInProgress(
          record.gcpName,
          "frontend"
        );

        return (
          <div className="version-container">
            {!!deploymentInProgress && <Spin size="small" />}
            <div className="new-version-container">
              <span>{version}</span>
              {!!deploymentInProgress && (
                <>
                  <IoMdArrowDropright />
                  <span className="new-version">
                    {deploymentInProgress.metadata?.version}
                  </span>
                </>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: formatMessage({ id: "websiteChat.table.header.frontendUrl" }),
      dataIndex: ["frontend", "url"],
    },
    {
      title: "Actions",
      render: (deployment: WebsiteChat.State) => {
        const anyDeploymentInProgress =
          !!findDeploymentInProgress(deployment.gcpName, "backend") ||
          !!findDeploymentInProgress(deployment.gcpName, "frontend");

        return (
          <div className="table-actions">
            <PermissionGate permissions={[Permission.setupCustomer]}>
              <MyButton
                type="primary"
                onClick={() => {
                  navigate("/channels/website-chat/deploy", {
                    state: { deployment },
                  });
                }}
                className="table-action"
                size="small"
                disabled={anyDeploymentInProgress}
              >
                <LocaleFormatter id="websiteChat.button.deployBackend" />
              </MyButton>
              {deployment.backend.imageTag && (
                <Popconfirm
                  placement="topLeft"
                  title={formatMessage({
                    id: "websiteChat.button.deployFrontend",
                  })}
                  description={
                    <div className="deploy-modal-container">
                      <div>
                        {formatMessage({
                          id: "websiteChat.button.bulkDeploy.enterFrontendVersion",
                        })}
                      </div>
                      <MyInput
                        value={selectedVersion}
                        onChange={(e) => setSelectedVersion(e.target.value)}
                      />
                    </div>
                  }
                  onConfirm={async () => {
                    const success = await dispatch(
                      deployFrontendAsync({
                        gcpName: deployment.gcpName,
                        version: selectedVersion,
                      })
                    );

                    if (success) {
                      message.success(
                        formatMessage({
                          id: "websitechat.deployFrontend.success",
                        })
                      );
                    }
                  }}
                  onOpenChange={() => setSelectedVersion("")}
                  okText={formatMessage({ id: "global.deploy" })}
                  okButtonProps={{
                    disabled: !selectedVersion,
                  }}
                  zIndex={1200}
                >
                  <MyButton
                    type="primary"
                    className="table-action"
                    size="small"
                    disabled={anyDeploymentInProgress}
                  >
                    <LocaleFormatter id="websiteChat.button.deployFrontend" />
                  </MyButton>
                </Popconfirm>
              )}
            </PermissionGate>
          </div>
        );
      },
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record: WebsiteChat.State) => ({
      disabled:
        !!findDeploymentInProgress(record.gcpName, "backend") ||
        !!findDeploymentInProgress(record.gcpName, "frontend"),
      name: record.gcpName,
    }),
  };

  return (
    <div className="container">
      <div className="title">
        <h1>
          <LocaleFormatter id="websiteChat.title" />
        </h1>
        <div className="title-actions">
          <PermissionGate permissions={[Permission.setupCustomer]}>
            <MyButton
              type="primary"
              onClick={() => navigate("/channels/website-chat/deploy")}
            >
              <LocaleFormatter id="websiteChat.button.newDeploy" />
            </MyButton>
          </PermissionGate>
        </div>
      </div>
      <div className="partners-content">
        <div className="bulk-deploy-container">
          {selectedRowKeys.length > 0 && (
            <>
              <p>
                For selected{" "}
                <span className="number-of-items">
                  {selectedRowKeys.length} items
                </span>
                :
              </p>
              <Popconfirm
                title={formatMessage({
                  id: "websiteChat.button.bulkDeployBackend",
                })}
                description={
                  <div className="deploy-modal-container">
                    <div>
                      {formatMessage({
                        id: "websiteChat.button.bulkDeploy.enterBackendVersion",
                      })}
                    </div>
                    <MyInput
                      value={selectedVersion}
                      onChange={(e) => setSelectedVersion(e.target.value)}
                    />
                  </div>
                }
                onConfirm={deployBulkBackend}
                onOpenChange={() => setSelectedVersion("")}
                okText={
                  <div className="deploy-count">
                    <span>{formatMessage({ id: "global.deploy" })}</span>
                    <span>{selectedRowKeys.length}</span>
                  </div>
                }
                okButtonProps={{
                  disabled: !selectedVersion,
                }}
                zIndex={1200}
              >
                <MyButton type="primary" size="small">
                  <LocaleFormatter id="websiteChat.button.deployBackend" />
                </MyButton>
              </Popconfirm>
              <Popconfirm
                title={formatMessage({
                  id: "websiteChat.button.bulkDeployFrontend",
                })}
                description={
                  <div className="bulk-deploy-modal-container">
                    <div>
                      {formatMessage({
                        id: "websiteChat.button.bulkDeploy.enterFrontendVersion",
                      })}
                    </div>
                    <MyInput
                      value={selectedVersion}
                      onChange={(e) => setSelectedVersion(e.target.value)}
                    />
                  </div>
                }
                onConfirm={deployBulkFrontend}
                onOpenChange={() => setSelectedVersion("")}
                okText={
                  <div className="deploy-count">
                    <span>{formatMessage({ id: "global.deploy" })}</span>
                    <span>{selectedRowKeys.length}</span>
                  </div>
                }
                okButtonProps={{
                  disabled: !selectedVersion,
                }}
                zIndex={1200}
              >
                <MyButton type="primary" size="small">
                  <LocaleFormatter id="websiteChat.button.deployFrontend" />
                </MyButton>
              </Popconfirm>
            </>
          )}
        </div>
        <div className="website-chat-filters-box">
          <ListFilters search={search} setSearch={setSearch} />
          <Table
            columns={columns}
            dataSource={deployments}
            onChange={onTableChange}
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            rowClassName={"deployment-row"}
            rowKey="gcpName"
            pagination={{
              total,
              pageSize: limit,
              current: page,
              showSizeChanger: false,
              showTotal: (total) => `Total: ${total} deployments`,
              onChange: (page) => {
                setPage(page);

                if (
                  page * limit > deployments.length &&
                  deployments.length < total
                ) {
                  const skip = (page - 1) * limit;

                  loadDeployments(generateQuery(skip));
                }
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default WebsiteChatPage;
