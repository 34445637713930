import type { Users } from '../../../common/routes/users';
import type { Dispatch } from '@reduxjs/toolkit';

import {
    apiAddUser,
    apiCreateToken,
    apiDeleteUser,
    apiEditUser,
    apiGetSingleUser,
    apiGetUsers,
    apiResetKeypair,
} from '@/api/users.api';
import { addUser, deleteUser, setUsers, updateUser } from '@/stores/users.store';

export const loadUsersAsync = () => {
    return async (dispatch: Dispatch) => {
        const { data } = await apiGetUsers();

        if (data) {
            dispatch(setUsers(data));
        }
    };
};

export const loadSingleUserAsync = (id: string) => {
    return async () => {
        const { data } = await apiGetSingleUser(id);

        if (data) {
            return data;
        }

        return null;
    };
};

export const addUserAsync = (payload: Users.PostBody) => {
    return async (dispatch: Dispatch) => {
        const { data } = await apiAddUser(payload);

        if (data) {
            dispatch(addUser(data));

            return true;
        }

        return false;
    };
};

export const editUserAsync = (id: string, payload: Users.PatchBody) => {
    return async (dispatch: Dispatch) => {
        const { data } = await apiEditUser(id, payload);

        if (data) {
            dispatch(updateUser(data));

            return true;
        }

        return false;
    };
};

export const deleteUserAsync = (id: string) => {
    return async (dispatch: Dispatch) => {
        const { data } = await apiDeleteUser(id);

        if (data) {
            dispatch(deleteUser(id));

            return true;
        }

        return false;
    };
};

export const resetKeypairAsync = (id: string) => {
    return async () => {
        const { data } = await apiResetKeypair(id);

        if (data) {
            return data;
        }

        return false;
    };
};

export const createTokenAsync = (payload: Users.ApiAuthentication.CreateTokenBody) => {
    return async () => {
        const { data } = await apiCreateToken(payload);

        if (data) {
            return data.token;
        }

        return null;
    };
};
