import type { ServerEvents } from './emitter';

import Events from './emitter';
import * as notification from './listeners/notification';
import * as pipeline from './listeners/pipeline';
import * as websiteChat from './listeners/website-chat';

const events = new Events() as ServerEvents;

export function attachListeners(){
    notification.attach(events);
    pipeline.attach(events);
    websiteChat.attach(events);
    events.connect();
}

export function detachListeners(){
    notification.detach(events);
    pipeline.detach(events);
    websiteChat.detach(events);
}
